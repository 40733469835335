<template>
  <div class="header-section" :class="imgLoading ? '' : 'min-height'">
    <img
      class="header-bg"
      width="45%"
      :src="require('@/assets/images/header-bg.png')"
      alt=""
    />
    <div v-if="imgLoading">
      <div class="max-width">
        <slick-carousel class="px-6 z-99 relative" v-bind="settings">
          <div v-for="(item, i) in headerItems" :key="i">
            <v-row align="center">
              <v-col cols="12" sm="5" md="5" class="pb-0 relative z-1" :offset-md="$vuetify.rtl ? 1:  0">
                <img
                  class="person-img object-contain"
                  :width="isMobile ? '100%' : '435px'"
                  :height="isMobile ? '300px' : ''"
                  :src="item.img"
                  @load="imgLoadingHandler"
                />
              </v-col>
              <v-col cols="12" sm="7" md="6" :offset-md="$vuetify.rtl ? 0:  1">
                <div class="w-100 z-9 relative">
                  <span class="darktext--text font-800 font-88">
                    {{ $t("homepage.exposedNew") }}
                    <span class="font-100">{{
                      $t("homepage.opportunities")
                    }}</span>
                  </span>
                  <p class="font-24 mt-2 mb-5">{{ desc }}</p>
                  <v-btn
                    color="register-primary"
                    elevation="0"
                    :height="isMobile ? '40px' : '55px'"
                    rounded
                    class="primary-btn-shadow"
                    @click="goDownHandler"
                  >
                    <span class="white--text font-700 font-18">{{
                      $t("homepage.getThePerfectJob")
                    }}</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>

          <!-- <template #prevArrow>
            <div class="custom-arrow">
              <img
                width="32px"
                :src="require('@/assets/images/icons/arrow-left-fill.svg')"
              />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-arrow">
              <img
                width="32px"
                :src="require('@/assets/images/icons/arrow-right-fill.svg')"
              />
            </div>
          </template> -->
        </slick-carousel>
      </div>
      <transition name="fade">
        <div class="bottom-border" v-if="bannerLoading"></div>
      </transition>
      <img
        class="blur-img"
        :src="require('@/assets/images/head-blur-img.png')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    settings: {
      dots: true,
      focusOnSelect: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    },
    imgLoading: false,
    bannerLoading: false,
    headerItems: [
      {
        title: "''",
        desc: "$t('homepage.headerDesc')",
        img: require("@/assets/images/about/about1.png"),
      },
      // {
      //   title: "''",
      //   desc: "$t('homepage.headerDesc')",
      //   img: require("@/assets/images/about/about2.png"),
      // },
    ],
  }),
  methods: {
    imgLoadingHandler() {
      this.imgLoading = true;
      setTimeout(() => {
        this.bannerLoading = true;
      }, 200);
    },
    goDownHandler() {
      let top;
      if (this.isMd) {
        top = document.querySelector("#home-types").offsetTop + 250;
      } else {
        top = document.querySelector("#home-about").offsetTop;
      }

      window.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  created() {
    setTimeout(() => {
      this.imgLoading = true;
    }, 700);
  },
};
</script>

<style lang="scss">
.header-section {
  position: relative;
  p {
    color: #5b5d5f;
  }
  .slick-prev {
    left: 0%;
  }
  .slick-next {
    right: 0%;
  }
  .slick-dots {
    padding-left: 0px !important;
    bottom: -75px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -30px;
    li {
      position: relative;
      width: 17px;
      button {
        width: 11.06px;
        height: 5.53px;
        background: #ffffff;
        border-radius: 34.4096px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.3s;
        &::before {
          display: none;
        }
      }
    }

    li.slick-active {
      width: 95px;
      button {
        background: white;
        width: 90px;
      }
    }
  }
}
</style>
