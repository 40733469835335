<template>
    <div class="home-page">
        <header-section
                :title="''"
                :desc="$t('homepage.headerDesc')"
                :img="require('@/assets/images/about/about1.png')"
        ></header-section>
        <div class="max-width">
            <region-section :data="homeCountries"></region-section>
            <types-section></types-section>
            <about-section id="home-about"></about-section>
            <recommendations-section :data="homeCountries"></recommendations-section>
            <get-in-touch></get-in-touch>
        </div>
    </div>
</template>

<script>
import HeaderSection from "./components/Header";
import RegionSection from "./components/Region.vue";
import TypesSection from "./components/Types.vue";
import AboutSection from "./components/About.vue";
import RecommendationsSection from "./components/Recommendations.vue";
import GetInTouch from "@/components/core/GetInTouch";

export default {
    components: {
        HeaderSection,
        RegionSection,
        TypesSection,
        AboutSection,
        RecommendationsSection,
        GetInTouch,
    },
    data: () => ({
        data: [],
        testimonials: [],
    }),
    computed: {
        homeCountries() {
            return this.data.slice(0, 4);
        },
    },
    methods: {
        async getData() {
            let {data} = await this.$axios.get("countries");
            if (data) {
                this.data = data.data;
            }
        },
        async getTestimonials() {
            let {testimonials} = await this.$axios.get("testimonials");
            if (testimonials) {
                this.testimonials = testimonials.data;
            }
        },

    },
    async created() {
        await this.getData();
        await this.getTestimonials();
    },
};
</script>

<style></style>
