<template>
  <div class="about-section">
    <div class="line mx-auto register-primary mb-4 mt-14"></div>
    <div class="text-center">
      <p class="font-48 font-800 darktext--text mb-0">
        {{ $t("homepage.seeYou") }}
      </p>
    </div>

    <v-row class="" align="center">
      <v-col cols="12" md="6">
        <img
          width="100%"
          :src="require('@/assets/images/home-about.png')"
          alt=""
        />
      </v-col>
      <v-col cols="12" md="6">
        <p class="description mt-5 font-20">{{ $t("homepage.aboutDesc") }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.about-section {
  margin-top: 10%;
  p {
    color: #636363;
  }
  @media (min-width: 960px) {
    .description {
      margin-bottom: 100px;
      line-height: 2;
      text-align: justify;
    }
  }
}
</style>
