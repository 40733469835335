<template>
  <div class="region-section">
    <div class="text-center pt-5">
      <div class="line mx-auto register-primary mb-4 mt-14"></div>
      <p class="font-32 font-800 darktext--text mb-0">
        {{ $t("homepage.exploreRegion") }}
      </p>
      <p class="font-24 darktext--text">
        {{ $t("homepage.welcomeHome") }}
      </p>
    </div>

    <v-row class="mt-7">
      <v-col cols="12"  sm="6" md="3" v-for="(country, i) in data" :key="i">
        <div class="h-100 radius-15" :class="i % 2 == 0 ? '' : 'top-25'">
          <country-cover :item="country"></country-cover>
        </div>
      </v-col>
    </v-row>

    <!-- show more -->
    <div class="text-center mt-14 mb-14">
      <div
        class="contries radius-10 px-6 py-3 d-inline-block cursor-pointer"
        @click="$router.push('/countries')"
      >
        <span class="font-16 blacktext--text">{{
          $t("homepage.showMoreCountries")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CountryCover from "../../../components/core/CountryCover.vue";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  components: { CountryCover },
  data: () => ({}),
};
</script>

<style lang="scss">
.contries {
  border-color: $register-primary;
  border-style: solid;
}
</style>
